import React, { CSSProperties, ReactElement } from "react";
import styles from "./styles.module.scss";
import { TLookupsList } from "@page-builder/ts/generated";
import { useDomainContext } from "@hooks/useDomainContext";
import { replacePlaceholders } from "@lib/sharedUtils";
import { gettingQueriesAsString } from "@lib/shared/routerQueriesToString";
import { useRouter } from "next/router";
import Image from "next/image";
export default function LookupsList({
    props = {},
}: {
    props?: TLookupsList["props"];
}): ReactElement {
    const { query } = useRouter();
    const {
        title,
        gridColumns = {
            sm: 1,
            md: 2,
            lg: 3,
            xl: 3,
        },
        lookup,
        urlTemplate,
        showLogos,
    } = props;

    const { pageBuilderLookupLists } = useDomainContext();

    const list =
        lookup && pageBuilderLookupLists
            ? pageBuilderLookupLists?.[lookup]
            : [];

    const slugifyLookup = (value: string) => {
        return value.trim().toLowerCase().replace(/ /g, "-");
    };

    const queryString = gettingQueriesAsString(query, "start", [
        "genericRoute",
        "keyword",
        "replaceKeyword",
        "slug",
    ]);

    return (
        <section
            className={`${styles["lookups-list"]} `}
            style={
                {
                    "--grid-cols-sm": gridColumns["sm"] || "2",
                    "--grid-cols-md":
                        gridColumns["md"] || gridColumns["sm"] || "3",
                    "--grid-cols-lg":
                        gridColumns["lg"] ||
                        gridColumns["md"] ||
                        gridColumns["sm"] ||
                        "4",
                    "--grid-cols-xl":
                        gridColumns["xl"] ||
                        gridColumns["lg"] ||
                        gridColumns["md"] ||
                        gridColumns["sm"] ||
                        "5",
                } as CSSProperties
            }
        >
            {title && (
                <div
                    className={`mb-6 ${styles.title}`}
                    dangerouslySetInnerHTML={{
                        __html: replacePlaceholders(title),
                    }}
                />
            )}

            <div
                className={`grid gap-10 lg:gap-20 mt-6 ${styles["items-grid"]}`}
            >
                {list?.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className={`text-center flex flex-col gap-4 ${
                                showLogos ? styles.filter : styles.item
                            }`}
                        >
                            <a
                                title={item.label}
                                href={`${
                                    urlTemplate?.replace(
                                        /\[item\]/gi,
                                        slugifyLookup(item?.label),
                                    ) ?? ""
                                }?replaceKeyword=true${
                                    queryString
                                        ? `${
                                              queryString.startsWith("&")
                                                  ? queryString
                                                  : `&${queryString}`
                                          }`
                                        : ""
                                }`}
                                className={`${
                                    showLogos
                                        ? "w-[72px] h-[72px] relative  block"
                                        : "font-light text-sm text-center"
                                } `}
                            >
                                {showLogos ? (
                                    <Image
                                        src={`/car-logos-new/${item.label.toLowerCase()}.png`}
                                        alt={item.label}
                                        layout="fill"
                                        objectFit="contain"
                                    />
                                ) : (
                                    <>{item.label}</>
                                )}
                            </a>
                        </div>
                    );
                })}
            </div>
        </section>
    );
}
